<template>
  <div class="header-container">
    <div class="search-warpper">
      <el-form :inline="true" :model="query">
        <el-form-item label="款式：">
          <el-input
            @change="$emit('search')"
            size="small"
            clearable
            style="width: 240px"
            placeholder="请输入款式名称"
            v-model="query.styleName"
          ></el-input>
        </el-form-item>
        <el-form-item label="分销商：">
          <el-input
            @change="$emit('search')"
            size="small"
            clearable
            style="width: 240px"
            placeholder="请输入分销商"
            v-model="query.createByName"
          ></el-input>
        </el-form-item>
        <el-form-item label="SKU：">
          <el-input
            @change="$emit('search')"
            size="small"
            clearable
            style="width: 240px"
            placeholder="请输入SKU"
            v-model="query.sku"
          ></el-input>
        </el-form-item>
        <el-form-item label="自定义SKU：">
          <el-input
            @change="$emit('search')"
            size="small"
            clearable
            style="width: 240px"
            placeholder="请输入自定义SKU"
            v-model="query.customSku"
          ></el-input>
        </el-form-item>
        <el-form-item label="状态：">
          <el-select
            size="small"
            @change="$emit('search')"
            style="width: 240px"
            v-model="query.isPublicOnShelf"
            clearable
            placeholder="请选择状态"
          >
            <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"> </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="作图时间：">
          <el-date-picker
            v-model="query.createTime"
            type="daterange"
            size="small"
            value-format="yyyy-MM-dd"
            placeholder="请选择时间"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            style="width: 300px"
            @change="$emit('search')"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="上架时间：">
          <el-date-picker
            v-model="query.putTime"
            type="daterange"
            size="small"
            value-format="yyyy-MM-dd"
            placeholder="请选择时间"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            style="width: 300px"
            @change="$emit('search')"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="原图名称：">
          <el-input
            @change="$emit('search')"
            size="small"
            clearable
            style="width: 240px"
            placeholder="请输入原图名称"
            v-model="query.imgTitle"
          ></el-input>
        </el-form-item>
        <el-form-item label="是否侵权：">
          <el-select
            size="small"
            @change="$emit('search')"
            style="width: 240px"
            v-model="query.isTort"
            clearable
            placeholder="请选择"
          >
            <el-option v-for="item in tortOptions" :key="item.value" :label="item.label" :value="item.value"> </el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button
            style="height: 32px; width: 48px; padding: 0; margin: 0 0 5px 17px"
            type="primary"
            @click="$emit('search')"
            icon="el-icon-search"
          ></el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    query: {
      type: Object,
      default: () => ({})
    },
    sup_this: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      options: [
        {
          value: undefined,
          label: '全部'
        },
        {
          value: 1,
          label: '已上架'
        },
        {
          value: 0,
          label: '未上架'
        }
      ],
      tortOptions: [
        {
          value: 'all',
          label: '全部'
        },
        {
          value: 1,
          label: '侵权'
        },
        {
          value: 0,
          label: '未侵权'
        }
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
.header-container {
  .search-warpper {
    display: flex;
    font-size: 14px;
    align-items: center;
    color: #1a1a1a;
    margin: 20px 0;
    background-color: #f7f8fa;
    padding: 20px 20px 5px;
    .el-form-item {
      margin-right: 20px;
    }
  }
}
</style>
